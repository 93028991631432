
@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';
.container{
    width: fit-content;
    height: auto;
    background: #EBECF0;
    border-radius: 4px;
    padding: 4px 8px;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #64697B;
  }
  .primary, .bypassed {
    background: $color-iceberg;
    color: $color-primary;
  }
  .secondary{
    background: $color-pink-lace;
    color: $button-pink;
  }
  .pending, .pending_review{
    background: $color-papaya-whip;
    color: $color-gold-drop;
  }
  .initiated {
    background: $color-iceberg;
    color: $color-primary;
  }
  .processing, .reviewed, .unfrozen {
    background: $color-lavender-mist;
    color: $button-blue;
  }
  .completed, .reviewed, .paid, .successful, .matched, .accepted {
    background: $color-peppermint;
    color: $color-shamrock-green;
  }
  .critical, .overdue, .frozen, .not_applicable{
    background: $color-misty-rose;
    color: $color-grapefruit;
  }
  .failed, .unpaid {
    background: $color-dawn-pink;
    color: $color-mist-blue;
  }
  .bypassed {
    background: $color-lavender-mist;
    color: $color-bypassed;
  }