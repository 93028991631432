// BREAKPOINTS
$xxxs: 320px;
$xxs: 414px;
$xs: 480px;
$s: 768px;
$m: 1024px;
$l: 1366px;
$lg: 1440px;
$xxl: 1600px;

// COLORS
$color-primary: #25397c;
$color-secondary: #d63a95;
$color-info: #007aff;
$color-bypassed: #1D7ADE;
$color-success: #12b76a;
$color-black: #0b0b0c;
$color-black-grey: #383a3c;
$color-grey: #7b7b84;
$color-grey-light: #b5b6b7;
$color-grey-dark: #2a2b2d;
$color-white: #ffffff;
$color-white-dark: #f7f7fa;
$color-light: #f2f2f2;
$color-text-remove: #f04438;
$color-bg-blue: #d1e9ff;
$color-bg-scroll-gray: #d9d9d9;
$color-pearl: #fcf0ef;
$color-aqua-spring: #e9faf2;
$color-sweet-pink: #fda29b;
$color-bg-blue: #d1e9ff;
$color-carmine-pink: #e64438;
$color-blue-chalk: #e4ecfa;
$color-dawn-pink: #ebecf0;
$color-iron: #d5d7df;
$color-shamrock-green: #01904f;
$color-black-squeeze: #ecfdf5;
$color-cool-grey: #959aaa;
$color-mountain-mist: #909193;
$color-gold-drop: #e28800;
$color-lavender-mist: #deedfd;
$color-papaya-whip: #fcf0d8;
$color-grey-cloud: #b4b7c3;
$color-iceberg: #e5edfc;
$color-tropical-blue: #ceddfb;
$color-mist-blue: #64697b;
$color-misty-rose: #fde1df;
$color-grapefruit: #d8382c;
$color-peppermint: #d8f8e9;
$color-pink-lace: #fae1ef;
$color-deep-cerise: #d63a95;
$color-gunmetal: #2c303c;
$color-tealish-blue: #f5f8ff;

// $color-light-1: #e2e2e2;
// $color-grey: #cccccc;
// $color-grey-3: #828282;
// $color-grey-4: #7a7a7a;

// $color-dark: #212529;
// $color-dark-lighter: #565a5c;
// $color-blue: #28a9d9;
// $color-border: #bcbcbc;
// $color-noti-unread: #f1f1f1;
// $color-disabled: #929091;
// $alpha: rgba(0, 0, 0, 0.2);
// $backdrop: rgba(0, 0, 0, 0.8);
// $color-error: #f56c6c;
// $color-locked-post: #000;
// $color-border-other: #dddddd;
// $color-background-selected: #e0e0e0;
// $color-no-result: #2b2b2d;
// $color-time: #0d0e15;
// $color-text-lighter: #bdbdbd;
// $color-text-lighten: #a0a0a0;
// $color-background-lighter: #eaeaea;

$border-color-default: #dbdbde;

// FONT SIZES
$font-size-default: 1rem;
$font-size-xxxs: 0.5rem;
$font-size-xxs: 0.625rem;
$font-size-xs: 0.75rem;
$font-size-s: 0.875rem;
$font-size-ms: 1.25rem;
$font-size-ml: 1.375rem;
$font-size-l: 1.75rem;
$font-size-xl: 3rem;

// TRANSITION
$transition-property: all;
$transition-duration: 0.3s;
$transition-duration-slow: 0.5s;
$transition-timing: ease;
$transition-timing-fast: cubic-bezier(1, 0, 0, 1);
$transition-delay: 0s;

// NAVBAR
$nav-bg: #f3f3f4;

//MODAL ADD ASSET
$bg-hover: #f2f5f7;
$bg-selected: #f3f3f4;
$bg-grey: #f3f3f4;

//BUTTON
$button-pink: #d63a95;
$border-button-pink: #db52a2;
$button-blue: #2e90fa;
$button-orange: #f79009;
$button-red: #f04438;
$button-green: #12b76a;

//ICON
$color-err: #ff4d4f;
$border-color-err: #ff7875;

//BACKGROUND
$bg-line: #f3f3f4;
$bg-sort: #f5f5f5;
//BORDER
$bd-active: #a6f4c5;
$bd-black: #eceded;
$bd-pending: #fedf89;
$bd-prinary: #80bcff;
$bd-tab: #3b5ac4;
$bd-circle-button: #d0d4e1;

$color-neutral-50: #f1f4fd;
$color-neutral-100: #e4e9f6;
$color-neutral-200: #ced6e8;
$color-neutral-300: #a5b2cf;
$color-neutral-400: #8392b7;
$color-neutral-500: #62739d;
$color-neutral-600: #495b86;
$color-neutral-700: #32446d;
$color-neutral-900: #111e3c;
$color-neutral-1000: #071024;

$color-primary-600: #3b61c2;

$color-second-500: #e94ba7;

$color-master-success-500: #00a55b;

$color-master-error-500: #ea4b3f;

